<template>
    <div class="flex">
        <div class="relative">
            <div class="absolute top-3 flex justify-between w-full px-4 z-10 pointer-events-none">
                <button
                    class="mt-2 h-7 w-7 text-gray-400 hover:text-gray-600 pointer-events-auto"
                    @click="$event.preventDefault();handleNav('prev')">
                    <Icon name="heroicons:chevron-left" />
                </button>
                <button
                    class="mt-2 h-7 w-7 text-gray-400 hover:text-gray-600 pointer-events-auto"
                    @click="$event.preventDefault();handleNav('next')">
                    <Icon name="heroicons:chevron-right" />
                </button>
            </div>

            <DatePicker
                ref="datePicker"
                v-model="date"
                :locale="locale"
                v-bind="$attrs"
                :model-modifiers="modelModifiers"
                class="calendar"
                :trim-weeks="trimWeeks"
                :mode="mode"
                :is-required="isRequired"
                :transition="'none'"
                :disabled-dates="disabledDates"
                :is-24hr="is24hr"
                :columns="columns" />
        </div>
    </div>
</template>

<script setup>
import {DatePicker} from 'v-calendar'
import {useI18n} from "vue-i18n";

const {locale} = useI18n()

defineOptions({
    inheritAttrs: false,
})

const props = defineProps({
    modelValue: {
        type: [String, Number, Date, Object, null],
        default: () => ({start: null, end: null})
    },
    modelModifiers: {
        type: Object,
        default: () => ({})
    },
    columns: {
        type: Number,
        default: 1
    },
    trimWeeks: {
        type: Boolean,
        default: true
    },
    mode: {
        type: String,
        default: 'date',
        validation: (value) => ['date', 'dateTime', 'time'].includes(value)
    },
    isRequired: {
        type: Boolean,
        default: false
    },
    disabledDates: {
        type: Array,
        default: () => []
    },
    is24hr: {
        type: Boolean,
        default: true
    }
})

const emits = defineEmits(['update:modelValue'])
const date = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
})

const datePicker = ref(null)
const calendarRef = computed(() => {
    if (datePicker.value) {
        return datePicker.value.calendarRef
    }
    return null
})

const handleNav = (direction) => {
    if (calendarRef.value) {
        if (direction === 'prev') {
            calendarRef.value.movePrev();
        } else {
            calendarRef.value.moveNext();
        }
    }
}

onMounted(async () => {
    await nextTick();
    if (props.modelValue instanceof Date && calendarRef.value) {
        calendarRef.value.focusDate(props.modelValue);
    }
})
</script>

<style lang="postcss">
.calendar {
    @apply text-center bg-white dark:bg-gray-800;
}
.calendar .vc-pane-layout {
    @apply grid gap-4 p-3;
}
.calendar .vc-title {
    @apply text-sm font-medium text-gray-900 dark:text-gray-200;
}
.calendar .vc-pane-header-wrapper {
    @apply hidden;
}
.vc-popover-content {
    @apply bg-white dark:bg-gray-800;
}
.vc-nav-title,
.vc-nav-item {
    @apply text-sm font-medium;
}
.vc-nav-item.is-current {
    @apply text-primary dark:text-primary;
}
.vc-nav-title,
.vc-nav-item,
.vc-nav-arrow {
    @apply text-gray-900 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 focus-within:shadow-[0_0_0_2px_rgba(0,0,0,0)] focus-within:shadow-primary-500/50;

}
.vc-nav-arrow svg{
    @apply h-5 w-5 stroke-1;
}
.vc-nav-item.is-active {
    @apply bg-primary dark:bg-primary;
}
.calendar .vc-weeks {
    @apply mt-4;
}
.calendar .vc-weekdays {
    @apply flex;
}
.calendar .vc-weekday {
    @apply text-gray-400 rounded-md w-9 font-normal text-[0.8rem];
}
.calendar .vc-weeks {
    @apply w-full flex flex-col [&>_div]:grid [&>_div]:grid-cols-7 [&>*:nth-of-type(2)]:rounded-t-lg [&>*:last-child]:rounded-b-lg;
}
.calendar .vc-week {
    @apply bg-white border-x-[1px] border-t-[1px] last:border-b-[1px] border-gray-300;
}
.dark .calendar .vc-week {
    @apply bg-gray-800;
}
.calendar .vc-day-content {
    @apply text-center text-sm font-normal transition-colors focus-visible:outline-none focus-within:shadow-none text-gray-900 dark:text-gray-200;
}
.calendar .vc-highlight-bg-solid {
    @apply bg-primary;
}
.calendar .vc-highlight-bg-light {
    @apply bg-primary-100 dark:bg-primary-800;
}
.calendar .vc-highlight-bg-outline {
    @apply bg-primary border-primary;
}
.calendar .vc-highlight-content-outline {
    @apply text-white;
}
.calendar .vc-disabled {
    @apply opacity-50;
}
.calendar .is-not-in-month * {
    @apply opacity-50;
}


.calendar .vc-time-picker {
    @apply bg-white dark:bg-gray-800 py-4 px-3 rounded-b-lg shadow-md;
}
.calendar .vc-time-weekday,
.calendar .vc-time-year {
    @apply text-gray-400 text-sm font-medium;
}
.calendar .vc-time-day,
.calendar .vc-time-month {
    @apply text-primary font-medium;
}
.calendar .vc-time-select-group {
    @apply bg-white dark:bg-gray-800 rounded-md text-gray-900 dark:text-gray-200;
}
.calendar .vc-base-icon {
    @apply text-primary;
}
.calendar .vc-base-select select {
    @apply text-gray-900 dark:text-gray-200 border-none hover:bg-gray-100 dark:hover:bg-gray-600 focus:ring-0 focus:outline-none;
}
.calendar .vc-base-select option {
    @apply text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-800;
}
</style>