<template>
    <div>
        <div v-if="disabled">
            <div
                class="flex justify-start items-center gap-x-4
                    rounded-md border-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                    appearance-none w-full py-2 px-3 leading-tight
                    focus:ring-primary focus:ring-2 focus:ring-inset sm:text-sm focus:outline-none focus:shadow-outline
                    bg-neutral-50 text-neutral-500 border-neutral-200 shadow-none
                    dark:text-gray-200 dark:placeholder:text-gray-500 dark:bg-gray-600 ">
                <Icon
                    name="heroicons:calendar"
                    class="h-4 w-4" />
                <span v-if="date?.start || date?.end">{{ $d(dayjs(date.start).toDate(), format) + '-' + $d(dayjs(date.end).toDate(), format) }}</span>
                <span v-else>{{ $d(dayjs(date).toDate(), format) }}</span>
            </div>
        </div>

        <Menu
            v-else
            as="div"
            class="relative inline-block text-left w-full">
            <div>
                <label
                    v-if="showLabel"
                    for="input_Ort der Durchführung"
                    class="block text-neutral-700 dark:text-gray-300 text-sm font-semibold mb-2">
                    {{ label }}
                </label>

                <MenuButton
                    :id="label"
                    class="flex w-full justify-between items-center gap-x-4 rounded-md bg-white dark:bg-gray-800 px-3 py-2
                text-sm text-gray-900 dark:text-gray-200
                shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <div class="inline-flex w-full justify-start items-center gap-x-4">
                        <div class="h-4 w-4 flex items-center">
                            <Icon
                                name="heroicons:calendar"
                                class="h-4 w-4" />
                        </div>

                        <div v-if="date && typeof date === 'object' && (('start' in date) || ('end' in date))">
                            <BaseDate
                                v-if="date.start"
                                class="text-left"
                                :format="format"
                                :date="date.start" />
                            <span> - </span>
                            <BaseDate
                                v-if="date.end"
                                class="text-left"
                                :format="format"
                                :date="date.end" />
                        </div>
                        <div v-else>
                            <BaseDate
                                class="text-left"
                                :format="format"
                                :date="date" />
                        </div>
                    </div>

                    <div
                        v-if="clearable"
                        class="h-4 w-4 flex items-center"
                        @click="clearDate">
                        <Icon
                            name="heroicons:x-circle"
                            class="h-4 w-4" />
                    </div>
                </MenuButton>
            </div>

            <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    :class="'absolute ' + anchorPoint + '-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'">
                    <BaseCalendar
                        v-if="type === 'range'"
                        v-model.range="date"
                        :columns="columns"
                        :mode="mode"
                        :is-required="isRequired"
                        :disabled-dates="disabledDates"
                        :trim-weeks="trimWeeks" />
                    <BaseCalendar
                        v-else
                        v-model="date"
                        :columns="columns"
                        :mode="mode"
                        :is-required="isRequired"
                        :disabled-dates="disabledDates"
                        :trim-weeks="trimWeeks" />
                </MenuItems>
            </Transition>
        </Menu>
    </div>
</template>

<script setup>
import BaseCalendar from '@/components/global/Inputs/BaseCalendar.vue'

const dayjs = useDayjs()

const props = defineProps({
    modelValue: {
        type: [String, Number, Date, Object, null],
        default: () => ({start: null, end: null})
    },
    showLabel: {
        type: Boolean,
        default: true
    },
    label: {
        type: String,
        default: ''
    },
    format: {
        type: String,
        default: 'dateShort'
    },
    type: {
        type: String,
        default: 'single',
        validator: (value) => ['single', 'range'].includes(value),
    },
    columns: {
        type: Number,
        default: 1
    },
    trimWeeks: {
        type: Boolean,
        default: true
    },
    mode: {
        type: String,
        default: 'date',
        validation: (value) => ['date', 'dateTime', 'time'].includes(value)
    },
    isRequired: {
        type: Boolean,
        default: false
    },
    disabledDates: {
        type: Array,
        default: () => []
    },
    disabled: {
        type: Boolean,
        default: false
    },
    anchorPoint: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value)
    },
    clearable: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['update:modelValue'])
const date = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
})

function clearDate(event) {
    event.preventDefault()

    if (props.type === 'range') {
        date.value = {start: null, end: null}
    } else {
        date.value = null
    }
}

onMounted(() => {
    // make sure the date will be set
    emits('update:modelValue', date.value)
})
</script>

<style scoped>

</style>