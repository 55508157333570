<template>
    <BaseInput
        v-if="formData && formRules && required"
        v-model="formData[fieldIdentifier]"
        :type="type"
        :step="step"
        :label="label"
        :name="fieldIdentifier"
        :placeholder="placeholder"
        :rules="formRules[fieldIdentifier]"
        :required="required[fieldIdentifier]"
        :disabled="!canEditData"
        :input-size="inputSize"
        :label-size="labelSize"
        :display="display"
        @input="checkFormFieldWildcard(fieldIdentifier, $event.target.value)">
        <slot
            name="customInputField"
            v-bind="{formData, formRules, required, canEditData}" />
        <template #inputHelper>
            <slot
                name="inputHelper"
                v-bind="{formData, formRules, required, canEditData}" />
            <BasePopover
                v-if="toolTip"
                size="sm"
                popover-wrapper-classes="self-center"
                :position="toolTip === true ? 'left' : toolTip">
                <template #trigger>
                    <div class="flex items-center">
                        <Icon
                            v-if="toolTip"
                            name="heroicons:question-mark-circle"
                            class="h-5 w-5 text-primary-500 hover:bg-primary-500 hover:text-white hover:rounded-full" />
                    </div>
                </template>
                <template #default>
                    <span class="text-neutral-500 dark:text-gray-300 mt-2">
                        {{ description }}
                    </span>
                </template>
            </BasePopover>
            <BasePopover
                v-if="personDataSuggestion"
                size="sm"
                position="left"
                y-position="top"
                popover-color="primary"
                :show-always="popoverShowAlways">
                <template #default>
                    Vorschlag aus Personendaten: {{ personDataSuggestion }}
                    <span
                        class="underline cursor-pointer text-primary-500 hover:text-primary-700"
                        @click="popoverShowAlways = false; $emit('adoptPersonDataSuggestion')">
                        >> Übernehmen
                    </span>
                </template>
            </BasePopover>
        </template>
    </BaseInput>
</template>

<script setup>
import {useFormProcessStore} from "@/store/formProcess";
import {useFormRulesStore} from "@/store/formRules";

const formProcessStore = useFormProcessStore()
const formRulesStore = useFormRulesStore()

const props = defineProps({
    fieldIdentifier: {
        type: String,
        required: true,
    },
    formFields: {
        type: Object,
        required: true
    },
    personDataSuggestion: {
        type: [Number, null],
        default: null
    },
    display: {
        type: String,
        default: 'inline'
    },
    inputSize: {
        type: String,
        default: '4'
    },
    labelSize: {
        type: String,
        default: '2'
    },
    description: {
        type: String,
        default: null
    },
    toolTip: {
        type: Boolean || String,
        default: false,
        description: 'true == left || right || center'
    },
})
const emit = defineEmits(['input', 'adoptPersonDataSuggestion'])

const popoverShowAlways = computed(() => {
    return props.personDataSuggestion !== formProcessStore.formProcessData.data[props.fieldIdentifier];
})

const formRules = formRulesStore.formRules
const required = formRulesStore.formFieldsRequired
const formFieldsWithWildcardInRule = formRulesStore.formFieldsWithWildcardInRule

const label = computed(() => props.formFields[props.fieldIdentifier].label)
const placeholder = computed(() => props.formFields[props.fieldIdentifier].placeholder)
const type = computed(() => {
    return (formRules[props.fieldIdentifier].includes('integer') ||
        formRules[props.fieldIdentifier].includes('decimal') ||
        formRules[props.fieldIdentifier].includes('currency'))
        ? 'number' : 'text'
})
const step = computed(() => {
    if (formRules[props.fieldIdentifier].includes('integer')) {
        return '1'
    } else if (formRules[props.fieldIdentifier].includes('decimal')) {
        const decimalPlaces = formRules[props.fieldIdentifier].split('|').find(rule => rule.includes('decimal')).split(':')[1]
        return '0.' + '0'.repeat(decimalPlaces - 1) + '1'
    } else {
        return ''
    }
})

const formData = computed(() => formProcessStore.formProcessData.data)

const canEditData = computed(() => {
    return formProcessStore.formProcessState === 'editable' && !props.formFields[props.fieldIdentifier].disabled
})

const fieldData = computed(() => {
    if (formProcessStore.formProcessData.data && formProcessStore.formProcessData.data[props.fieldIdentifier] !== undefined) {
        return formProcessStore.formProcessData.data[props.fieldIdentifier]
    } else {
        return null
    }
})

watch(fieldData, (newValue) => {
    checkFormFieldWildcard(props.fieldIdentifier, newValue)
})

function checkFormFieldWildcard(fieldIdentifier, value) {
    if (formFieldsWithWildcardInRule.includes(fieldIdentifier)) {
        formRulesStore.updateFormRules()
    }

    emit('input', value)
}

</script>